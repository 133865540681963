<template>
    <div class="app-container">
        <el-form :model="query" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="订单号" prop="orderSn">
                <el-input v-model="query.orderSn" placeholder="请输入商品名称" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
                <el-input v-model="query.phone" placeholder="请输入手机号" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="订单状态" prop="status" >
                <el-select v-model="query.status" placeholder="请选择状态" clearable size="small" @change="getList">
                    <el-option v-for="dict in dictType" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="下单日期" >
                <el-date-picker
                    v-model="dateList"
                    type="daterange"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    placeholder="选择日期"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                    @change="selectDate"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
            </el-form-item>
        </el-form>

        <el-table class="tableList" width="100%" v-loading="loading" :data="list" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="订单号" align="center" prop="orderSn" />
            <el-table-column label="归属站点" align="center" prop="siteName" />
            <el-table-column prop="itemList" width="520">
                <template #header>
                    <el-row class="order-row-item order-border-none">
                        <el-col :span="4">产品图</el-col>
                        <el-col :span="6">产品名称</el-col>
                        <el-col :span="6">详情/规格</el-col>
                        <el-col :span="4">数量</el-col>
                        <el-col :span="4">单价(元)</el-col>
                    </el-row>
                </template>
                <template #default="scope">
                    <div style="flex:1;display:flex;flex-direction: column;">
                        <div v-for="(order, index) in scope.row.itemList" :key="order.id" style="flex: 1;height:100%">
                            <el-row class="order-row-item" style="height:100%">
                                <el-col :span="4">
                                    <img :src="order.skuPic" width="80px" alt="image error">
                                </el-col>
                                <el-col :span="6">{{ order.spuName }}</el-col>
                                <el-col :span="6">{{ order.skuName }}</el-col>
                                <el-col :span="4">{{ order.skuQuantity + order.sendOutNum }}</el-col>
                                <el-col :span="4">
                                    <span style="color: red; font-weight: bold;">￥{{ order.skuPrice }}</span>
                                </el-col>
                            </el-row>
                            <el-divider
                                style="margin: 0 -8px;width:calc(100% + 16px);border-color:var(--el-border-color-light)"
                                v-if="index < scope.row.itemList.length - 1" />
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="订单金额" align="center" prop="payMoney">
                <template #default="scope">
                    <span style="color: red; font-weight: bold;">￥{{ scope.row.payMoney }}</span>
                </template>
            </el-table-column>
            <el-table-column label="分佣金额" align="center" prop="saleRatio">
                <template #default="scope">
                    <span style="color: red; font-weight: bold;">￥{{ scope.row.saleRatio == null ? 0:scope.row.saleRatio }}</span>
                </template>
            </el-table-column>
            <el-table-column label="收货人" align="center" prop="name" />
            <el-table-column label="手机号" align="center" prop="phone" width="120"/>
            <el-table-column label="收货地址" align="center" prop="province" width="180">
                <template #default="scope">
                    <div>{{ scope.row.province }}-{{ scope.row.city }}-{{ scope.row.region }}</div>
                    <div>{{ scope.row.detailAddress }}</div>
                </template>
            </el-table-column>
            <el-table-column label="订单状态" align="center" prop="publishStatus">
                <template slot-scope="scope">
                    <el-tag :type="orderStatusDict[scope.row.status].color">{{ orderStatusDict[scope.row.status].label}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" align="center" prop="createTime" width="160" />


            <!-- <el-table-column label="操作" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit"
                        @click="handleUpdate(scope.row)" v-hasPermi="['pms:spuInfo:edit']">修改</el-button>
                </template>
            </el-table-column> -->
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="query.current" :limit.sync="query.size"
            @pagination="getList" />
    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    name: 'OrderList',
    data() {
        return {
            // 列表
            list: [],
            // 选择日期
            dateList: [],
            // 类型
            dictType: [
                { value: -1, label: '已关闭' },
                { value: 0, label: '待支付' },
                { value: 1, label: '已支付' },
                { value: 2, label: '已完成' },
                { value: 3, label: '售后' },
            ],
            // 状态
            orderStatusDict: {
                '-1': { color: 'info', label: '已关闭' },
                '0': { color: 'warning', label: '待支付' },
                '1': { color: '', label: '待配送' },
                '2': { color: 'success', label: '已完成' },
                '3': { color: 'danger', label: '售后' },
            },
            // 总条数
            total: 0,
            // 遮罩层
            loading: true,
            // 查询
            query: {
                current: 1,
                size: 10,
                orderSn: '',
                phone: '',
                status: null,
                startDate: '',
                endDate: '',
                payType: 1,
                type: 0
            },
            // 选择
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
        }
    },
    created() {
        this.getList()
    },
    methods: {
        // 获取列表数据
        getList() {
            this.loading = true
            api.orderList(this.query).then(res => {
                this.total = res.total
                this.list = res.records
                this.loading = false
            })
        },
        // 搜索
        handleQuery() {
            this.getList()
        },
        /** 选择日期 */
        selectDate(e) {
            console.log(e);
            if (e != null && e.length > 0) {
                this.query.current = 1
                this.query.startDate = e[0]
                this.query.endDate = e[1]
                this.getList()
            }else {
                this.query.current = 1
                this.query.startDate = ''
                this.query.endDate = ''
                this.getList()
            }
        },
        // 重置
        reset() {
            this.query = {
                current: 1,
                size: 10,
                orderSn: '',
                phone: '',
                status: null,
                startDate: '',
                endDate: '',
                payType: 1,
                type: 0
            }
            this.dateList = [],
            this.getList()
        },
        /** 多选框选中数据 */
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
    }
}

</script>


<style lang="scss" scoped>
.tableList {
    border: 1px solid var(--el-table-border-color);
    border-bottom: none;

    :deep(thead .cell) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    :deep(thead th:last-child .cell) {
        justify-content: center;
    }

    :deep(thead .el-icon) {
        margin-top: -2px;
        cursor: pointer;
    }

    .primary {
        cursor: pointer;
        color: var(--el-color-primary);
    }

    :deep(.el-button) {
        margin-left: 0;
        margin-right: 12px;
    }

    :deep(.el-table__body td.el-table__cell) {
        border-right: 1px solid var(--el-border-color-light);
        padding: 0;

        &:last-child {
            border-right: none;
        }
    }

    .order-row-item {
        width: 100%;

        :deep(.el-col) {
            border-right: 1px solid var(--el-border-color-light);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            &:last-child {
                border-right: none;
            }

            &:first-child,
            &:nth-child(2) {
                text-align: left;
                justify-content: flex-start;
                padding-left: 8px;
            }
        }
    }

    .order-border-none {
        :deep(.el-col) {
            border: none;
        }
    }
}</style>